// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDazWuu75Qu17ITmQQ4KQncik_DJMmwkFs",
  authDomain: "oldholy-demo.firebaseapp.com",
  projectId: "oldholy-demo",
  storageBucket: "oldholy-demo.firebasestorage.app",
  messagingSenderId: "158406261290",
  appId: "1:158406261290:web:bb30ac4a54b0ef5fe56153",
  measurementId: "G-DE37BQNX0R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
export default app;
