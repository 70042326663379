import React, { useEffect, useRef, useState } from 'react';

function MicrophoneWaveform() {
  const requestRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);

  // We'll store three separate path strings here (one for each wave)
  const [wavePaths, setWavePaths] = useState(['', '', '']);
  const [micActive, setMicActive] = useState(false);

  // SVG size
  const WIDTH = 630;
  const HEIGHT = 380;

  useEffect(() => {
    const setupAudio = async () => {
      try {
        // Create audio context
        const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
        
        // Create main analyser
        const analyser = audioCtx.createAnalyser();
        analyser.fftSize = 1024;
        analyser.smoothingTimeConstant = 0.9;

        // Create a gain node to mix the sources
        const gainNode = audioCtx.createGain();
        gainNode.gain.value = 0.5;

        // Set up microphone
        const micStream = await navigator.mediaDevices.getUserMedia({
          audio: true
        });
        const micSource = audioCtx.createMediaStreamSource(micStream);
        micSource.connect(gainNode);

        // Set up audio element analysis
        const audioElements = document.getElementsByTagName('audio');
        if (audioElements.length > 0) {
          const audioElement = audioElements[0];
          const audioSource = audioCtx.createMediaElementSource(audioElement);
          audioSource.connect(gainNode);
          audioSource.connect(audioCtx.destination); // This ensures we can still hear the audio
        }

        // Connect gain node to analyser
        gainNode.connect(analyser);
        
        analyserRef.current = analyser;
        dataArrayRef.current = new Uint8Array(analyser.frequencyBinCount);

        setMicActive(true);
        requestRef.current = requestAnimationFrame(updateWaves);

        return () => {
          micStream.getTracks().forEach(track => track.stop());
          audioCtx.close();
        };
      } catch (err) {
        console.error('Audio setup error:', err);
      }
    };

    setupAudio();

    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
  }, []);

  /**
   * Rough "volume" in [0..1], by averaging how far samples deviate from 128.
   */
  const getVolume = (timeDomainData) => {
    let total = 0;
    for (let i = 0; i < timeDomainData.length; i++) {
      total += Math.abs(timeDomainData[i] - 128);
    }
    const avg = total / timeDomainData.length;
    return Math.min(avg / 128, 1);
  };

  /**
   * A profile function that is 0 at t=0 & t=1, peaking at t=0.5.
   * This ensures the wave is flat at the edges of its domain.
   * We'll only draw the wave between startT..endT horizontally.
   */
  const profile = (t) => {
    return 4 * t * (1 - t);
  };

  /**
   * Example wave shapes: each wave gets amplitude from mic volume
   * but has a different phase offset so they cross each other.
   */
  const wave1 = (t, amplitude) => {
    return amplitude * Math.sin(2 * Math.PI * 2.5 * t);
  };
  const wave2 = (t, amplitude) => {
    return amplitude * Math.sin(2 * Math.PI * 0.5 * t + Math.PI / 2);
  };
  const wave3 = (t, amplitude) => {
    return amplitude * Math.sin(2 * Math.PI * 1.5 * t + Math.PI);
  };

  /**
   * Generate a SINGLE path that:
   * - Draws a line from x=0 => x=(startT*width) at the center Y
   * - Draws a smooth wave from x=(startT*width)..(endT*width)
   * - Draws a line from x=(endT*width) => x=width at the center Y
   */
  const buildWavePath = (waveFn, volume) => {
    const centerY = HEIGHT / 2;
    const startT = 0.1;
    const endT = 0.9;
    const maxAmplitude = 300 * volume;

    const samples = 60;
    const wavePoints = [];
    for (let i = 0; i <= samples; i++) {
      const localRatio = i / samples;
      const T = startT + localRatio * (endT - startT);
      const x = T * WIDTH;
      const localAmp = profile(localRatio) * maxAmplitude;
      const yOffset = waveFn(localRatio, localAmp);
      const y = centerY + yOffset;
      wavePoints.push({ x, y });
    }

    const waveCurve = generateSmoothSubPath(wavePoints);

    const leftX = 0;
    const rightX = WIDTH;
    const leftWaveX = wavePoints[0].x;

    let d = `M ${leftX} ${centerY} L ${leftWaveX} ${wavePoints[0].y}`;
    d += waveCurve;
    d += ` L ${rightX} ${centerY}`;

    return d;
  };

  /**
   * Turn an array of {x, y} into a "Q" path (omitting the initial M).
   * We'll assume we already have an "L" or "M" to the first point.
   */
  const generateSmoothSubPath = (points) => {
    if (points.length < 2) return '';
    let d = '';
    for (let i = 1; i < points.length - 2; i++) {
      const cx = (points[i].x + points[i + 1].x) / 2;
      const cy = (points[i].y + points[i + 1].y) / 2;
      d += ` Q ${points[i].x},${points[i].y} ${cx},${cy}`;
    }
    const n = points.length - 1;
    d += ` T ${points[n].x},${points[n].y}`;
    return d;
  };

  /**
   * Each animation frame:
   * 1) measure volume
   * 2) build each wave path
   */
  const updateWaves = () => {
    const analyser = analyserRef.current;
    const timeData = dataArrayRef.current;
    if (!analyser || !timeData) return;

    analyser.getByteTimeDomainData(timeData);
    const volume = getVolume(timeData);

    const path1 = buildWavePath(wave1, volume);
    const path2 = buildWavePath(wave2, volume);
    const path3 = buildWavePath(wave3, volume);

    setWavePaths([path1, path2, path3]);

    requestRef.current = requestAnimationFrame(updateWaves);
  };

  return (
    <div style={{ width: WIDTH, height: HEIGHT }}>
      {micActive ? (
        <>
          <svg width={WIDTH} height={HEIGHT} style={{ position: 'absolute', top: '-4px', left: 0, zIndex: 0, opacity: 1 }}>
            <path d={wavePaths[0]} fill="none" stroke="#FFE100" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
            <path d={wavePaths[1]} fill="none" stroke="#FFE100" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
            <path d={wavePaths[2]} fill="none" stroke="#FFE100" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
          </svg>
          <svg width={WIDTH} height={HEIGHT} style={{ position: 'absolute', top: '4px', left: 0, zIndex: 0, opacity: 1 }}>
            <path d={wavePaths[0]} fill="none" stroke="#489BFF" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
            <path d={wavePaths[1]} fill="none" stroke="#489BFF" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
            <path d={wavePaths[2]} fill="none" stroke="#489BFF" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
          </svg>
          <svg width={WIDTH} height={HEIGHT} style={{ position: 'absolute', top: 0, left: 0 }}>
            <path d={wavePaths[0]} fill="none" stroke="#FD0100" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
            <path d={wavePaths[1]} fill="none" stroke="#FD0100" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
            <path d={wavePaths[2]} fill="none" stroke="#FD0100" strokeWidth="8" opacity="0.5" strokeLinecap="round" />
          </svg>
        </>
      ) : (
        <p>Waiting for microphone access...</p>
      )}
    </div>
  );
}

export default MicrophoneWaveform;
