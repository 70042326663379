import React from 'react';

const PowerButton = ({ isActive, isHolding, holdProgress, onClick, selectedChannel, screenState, offerCountdown }) => {
  // Calculate which rows should be highlighted based on progress (0-5 seconds)
  const getRowHighlights = () => {
    const totalRows = 4; // We only need to highlight 4 rows since bottom row is always yellow
    const highlightedRows = Math.floor((holdProgress / 4) * totalRows);
    return Array(totalRows).fill(false).map((_, index) => index >= (totalRows - highlightedRows));
  };

  // For offer countdown, we want to show the rows counting down
  const getOfferCountdownHighlights = () => {
    const totalRows = 5; // We only need to highlight 4 rows since bottom row is always green
    const highlightedRows = Math.floor((offerCountdown / 5) * totalRows);
    return Array(totalRows).fill(false).map((_, index) => index >= (totalRows - highlightedRows));
  };

  if (!isActive) {
    return (
      <img 
        src="/assets/buttons/off.svg" 
        alt="power"
        className="w-[34px] h-[34px]"
        onClick={onClick}
      />
    );
  }

  if (screenState === 'offer') {
    const rowHighlights = getOfferCountdownHighlights();
    return (
      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <g>
          {/* First 4 rows from top */}
          <circle cx="3.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#1CD760" : "#303030"}/>
          <circle cx="10.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#1CD760" : "#303030"}/>
          <circle cx="17.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#1CD760" : "#303030"}/>
          <circle cx="24.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#1CD760" : "#303030"}/>
          <circle cx="31.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#1CD760" : "#303030"}/>

          <circle cx="3.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#1CD760" : "#303030"}/>
          <circle cx="10.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#1CD760" : "#303030"}/>
          <circle cx="17.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#1CD760" : "#303030"}/>
          <circle cx="24.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#1CD760" : "#303030"}/>
          <circle cx="31.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#1CD760" : "#303030"}/>

          <circle cx="3.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#1CD760" : "#303030"}/>
          <circle cx="10.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#1CD760" : "#303030"}/>
          <circle cx="17.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#1CD760" : "#303030"}/>
          <circle cx="24.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#1CD760" : "#303030"}/>
          <circle cx="31.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#1CD760" : "#303030"}/>

          <circle cx="3.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#1CD760" : "#303030"}/>
          <circle cx="10.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#1CD760" : "#303030"}/>
          <circle cx="17.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#1CD760" : "#303030"}/>
          <circle cx="24.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#1CD760" : "#303030"}/>
          <circle cx="31.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#1CD760" : "#303030"}/>

          {/* Bottom row - always green */}
          <circle cx="3.5" cy="31.0293" r="3" fill="#1CD760"/>
          <circle cx="10.5" cy="31.0293" r="3" fill="#1CD760"/>
          <circle cx="17.5" cy="31.0293" r="3" fill="#1CD760"/>
          <circle cx="24.5" cy="31.0293" r="3" fill="#1CD760"/>
          <circle cx="31.5" cy="31.0293" r="3" fill="#1CD760"/>
        </g>
      </svg>
    );
  }

  if (isHolding) {
    const rowHighlights = getRowHighlights();
    return (
      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <g>
          {/* First 4 rows from top */}
          <circle cx="3.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#FFE100" : "#303030"}/>
          <circle cx="10.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#FFE100" : "#303030"}/>
          <circle cx="17.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#FFE100" : "#303030"}/>
          <circle cx="24.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#FFE100" : "#303030"}/>
          <circle cx="31.5" cy="3.0293" r="3" fill={rowHighlights[0] ? "#FFE100" : "#303030"}/>

          <circle cx="3.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#FFE100" : "#303030"}/>
          <circle cx="10.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#FFE100" : "#303030"}/>
          <circle cx="17.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#FFE100" : "#303030"}/>
          <circle cx="24.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#FFE100" : "#303030"}/>
          <circle cx="31.5" cy="10.0293" r="3" fill={rowHighlights[1] ? "#FFE100" : "#303030"}/>

          <circle cx="3.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#FFE100" : "#303030"}/>
          <circle cx="10.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#FFE100" : "#303030"}/>
          <circle cx="17.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#FFE100" : "#303030"}/>
          <circle cx="24.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#FFE100" : "#303030"}/>
          <circle cx="31.5" cy="17.0293" r="3" fill={rowHighlights[2] ? "#FFE100" : "#303030"}/>

          <circle cx="3.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#FFE100" : "#303030"}/>
          <circle cx="10.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#FFE100" : "#303030"}/>
          <circle cx="17.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#FFE100" : "#303030"}/>
          <circle cx="24.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#FFE100" : "#303030"}/>
          <circle cx="31.5" cy="24.0293" r="3" fill={rowHighlights[3] ? "#FFE100" : "#303030"}/>

          {/* Bottom row - always yellow */}
          <circle cx="3.5" cy="31.0293" r="3" fill="#FFE100"/>
          <circle cx="10.5" cy="31.0293" r="3" fill="#FFE100"/>
          <circle cx="17.5" cy="31.0293" r="3" fill="#FFE100"/>
          <circle cx="24.5" cy="31.0293" r="3" fill="#FFE100"/>
          <circle cx="31.5" cy="31.0293" r="3" fill="#FFE100"/>
        </g>
      </svg>
    );
  }

  // Add new condition for success state
  if (screenState === 'success') {
    return (
      <img 
        src={"/assets/buttons/correct.svg"}
        alt="correct"
        className="w-[34px] h-[34px]"
        onClick={onClick}
      />
    );
  }

  if (screenState === 'failure') {
    return (
      <img 
        src={"/assets/buttons/incorrect.svg"}
        alt="incorrect"
        className="w-[34px] h-[34px]"
        onClick={onClick}
      />
    );
  }

  // Add new condition for live state
  if (screenState === 'live') {
    return (
      <img 
        src={"/assets/buttons/live.svg"}
        alt="live"
        className="w-[34px] h-[34px]"
        onClick={onClick}
      />
    );
  }

  // Add new condition for when device is on but nothing is playing
  if (!selectedChannel && screenState !== 'call-in') {
    return (
      <img 
        src={"/assets/buttons/ready.svg"}
        alt="ready"
        className="w-[34px] h-[34px]"
        onClick={onClick}
      />
    );
  }

  // Add new condition for call-in state
  if (screenState === 'call-in') {
    return (
      <img 
        src={"/assets/buttons/call in.svg"}
        alt="call in"
        className="w-[34px] h-[34px]"
        onClick={onClick}
      />
    );
  }

  if (screenState === 'ringing') {
    return (
      <img 
        src={"/assets/buttons/calling.svg"}
        alt="ringing"
        className="w-[34px] h-[34px]"
        onClick={onClick}
      />
    );
  }

  return (
    <img 
      src={"/assets/buttons/backspace.svg"}
      alt="backspace"
      className="w-[34px] h-[34px]"
      onClick={onClick}
    />
  );
};

export default PowerButton; 