import React from 'react';

const NavigationBar = ({ view, setView }) => {
  return (
    <div className="flex relative w-full items-center justify-between gap-8 py-6">
      <svg xmlns="http://www.w3.org/2000/svg" width="27" height="8" viewBox="0 0 27 8" fill="none">
        <path d="M0 3.2H26.6667V4.8H0V3.2Z" fill="white"/>
        <path d="M21.8672 8V0H23.4672V8H21.8672Z" fill="white"/>
      </svg>
      <div className="absolute left-1/2 -translate-x-1/2 flex items-center justify-center p-1 gap-1 border border-grey-1 rounded-[6px]">
        <button 
          onClick={() => setView('interface')} 
          className={`font-medium text-[12px] tracking-[0.1em] rounded-[2px] w-[100px] py-1 hover:text-white transition-colors ${
            view === 'interface' 
              ? 'text-white bg-grey-1' 
              : 'text-grey-3'
          }`}
        >
          INTERFACE
        </button>
        <button 
          onClick={() => setView('model')} 
          className={`font-medium text-[12px] tracking-[0.1em] rounded-[2px] w-[100px] py-1 hover:text-white transition-colors ${
            view === 'model' 
              ? 'text-white bg-grey-1' 
              : 'text-grey-3'
          }`}
        >
          MODEL
        </button>
        {/* <button 
          onClick={() => setView('spotify')} 
          className={`font-medium text-[12px] tracking-[0.1em] rounded-[2px] w-[100px] py-1 hover:text-white transition-colors ${
            view === 'spotify' 
              ? 'text-white bg-grey-1' 
              : 'text-grey-3'
          }`}
        >
          SPOTIFY
        </button> */}
        <button 
          onClick={() => setView('campaign')} 
          className={`font-medium text-[12px] tracking-[0.1em] rounded-[2px] w-[100px] py-1 hover:text-white transition-colors ${
            view === 'campaign' 
              ? 'text-white bg-grey-1' 
              : 'text-grey-3'
          }`}
        >
          CAMPAIGN
        </button>
      </div>
      <div className="font-medium text-[16px] tracking-[0.1em] text-white pr-8">OLDHOLY</div>
    </div>
  );
};

export default NavigationBar; 