import React from 'react';
import MicrophoneWaveform from './MicrophoneWaveform';
import Glyph from './Glyph';

const DisplayScreen = ({
  screenState,
  selectedChannel,
  typedGlyphs,
  currentTime,
  isHolding,
  handleKeyHold,
  handleKeyRelease,
  channels,
  handleCallClick,
  campaignData,
  eventData,
  preloadedImage
}) => {
  return (
    <div className="flex relative items-center justify-center w-full h-full">
      {/* POWER OFF */}
      {screenState === 'inactive' && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex w-full min-h-[70px] bg-black border-b border-grey-2"></div>
          <div className="relative w-full h-full bg-black">
            <div className="absolute inset-0 grid grid-cols-4 overflow-hidden p-4 gap-2">
              {[...Array(8)].map((_, index) => (
                <div 
                  key={index} 
                  className="h-full w-full border border-grey-2 bg-black"
                ></div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* READY */}
      {(screenState === 'active' || screenState === 'intermission') && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex w-full items-center justify-between min-h-[70px] bg-black border-b border-grey-2 px-8">
            {selectedChannel ? (
              <>
                <div className="flex items-center justify-center text-yellow">
                  {typedGlyphs.map((glyph, index) => (
                    <Glyph key={index} name={glyph.name} color="#FFE500" />
                  ))}
                </div>
                <div className="flex flex-col items-end justify-center">
                  <div className="text-[12px] font-medium tracking-[0.1em] text-white uppercase">{selectedChannel.title}</div>
                  <div className="text-[12px] font-medium tracking-[0.1em] text-white uppercase">{selectedChannel.artist}</div>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center justify-start">
                  <div className="text-[20px] font-medium tracking-[0.1em] text-white uppercase">
                    {currentTime.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' })}
                  </div>
                </div>
                <div className="flex items-center justify-start">
                  <div className="text-[12px] font-medium tracking-[0.1em] text-yellow uppercase">
                    hold any key to begin
                  </div>
                </div>
              </>
            )}
          </div>
          
          <div className="relative w-full h-full">
            {selectedChannel && (
              <>
                <img 
                  src={selectedChannel.albumCover}
                  alt={selectedChannel.name}
                  className="absolute inset-0 w-full h-full object-cover z-0 p-4"
                />
                <div className="absolute inset-3 grid grid-cols-4 overflow-hidden">
                  {[...Array(8)].map((_, index) => (
                    <div 
                      key={index} 
                      className="h-full w-full border-[4px] border-black"
                    ></div>
                  ))}
                </div>
              </>
            )}
            <div className="absolute inset-0 grid grid-cols-4 overflow-hidden p-4 gap-2">
              {channels.map((channel, index) => {
                const isSelected = selectedChannel?.id === channel.id;
                return (
                  <div 
                    key={index} 
                    onMouseDown={() => handleKeyHold(channel)}
                    onMouseUp={handleKeyRelease}
                    onMouseLeave={handleKeyRelease}
                    className={`flex flex-col w-full h-full p-2 text-[12px] font-medium text-white cursor-pointer border border-grey-2 ${
                      isHolding && selectedChannel?.id === channel.id ? 'bg-[#FFE100]' : 'bg-black/50'
                    }`}
                  >
                    <Glyph 
                      name={channel.name} 
                      color={isHolding && selectedChannel?.id === channel.id ? '#000000' : 'white'} 
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      {/* RINGING */}
      {screenState === 'ringing' && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex w-full min-h-[70px] bg-black border-b border-grey-2 px-8">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center justify-center text-white">
                {typedGlyphs.map((glyph, index) => (
                  <Glyph key={index} name={glyph.name} color="#FFFFFF" />
                ))}
              </div>
              <div className="text-[12px] font-medium tracking-[0.1em] text-white uppercase animate-pulse">
                CALLING...
              </div>
            </div>
          </div>
          <div className="relative w-full h-full bg-black">
            <div className="absolute inset-3 overflow-hidden gap-2">
              <div className="absolute animate-pulse-white h-full w-full flex items-center justify-center"></div>
              <div className="absolute inset-0 grid grid-cols-4 overflow-hidden">
                {[...Array(8)].map((_, index) => (
                  <div 
                    key={index} 
                    className="h-full w-full border-[4px] border-black"
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* LIVE */}
      {screenState === 'live' && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex w-full min-h-[70px] bg-black border-b border-grey-2 px-8">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center justify-center text-white">
                {typedGlyphs.map((glyph, index) => (
                  <Glyph key={index} name={glyph.name} color="#FFFFFF" />
                ))}
              </div>
              <div className="text-[12px] font-medium tracking-[0.1em] text-red uppercase">
                LIVE ON AIR
              </div>
            </div>
          </div>
          <div className="relative w-full h-full bg-black">
            <div className="absolute inset-3 bg-white overflow-hidden gap-2">
              <MicrophoneWaveform />
              <div className="absolute inset-0 grid grid-cols-4 overflow-hidden">
                {[...Array(8)].map((_, index) => (
                  <div 
                    key={index} 
                    className="h-full w-full border-[4px] border-black"
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* SUCCESS */}
      {screenState === 'success' && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex w-full min-h-[70px] bg-black border-b border-grey-2 px-8">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center justify-center text-white">
                {typedGlyphs.map((glyph, index) => (
                  <Glyph key={index} name={glyph.name} color="#1CD760" />
                ))}
              </div>
              <div className="text-[12px] font-medium tracking-[0.1em] text-green uppercase">
                CORRECT!
              </div>
            </div>
          </div>
          <div className="relative w-full h-full bg-black">
            <div className="absolute inset-3 animate-pulse-green overflow-hidden gap-2">
              <div className="absolute inset-0 grid grid-cols-4 overflow-hidden">
                {[...Array(8)].map((_, index) => (
                  <div 
                    key={index} 
                    className="h-full w-full border-[4px] border-black"
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* FAILURE */}
      {screenState === 'failure' && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex w-full min-h-[70px] bg-black border-b border-grey-2 px-8">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center justify-center text-white">
                {typedGlyphs.map((glyph, index) => (
                  <Glyph key={index} name={glyph.name} color="#FD0100" />
                ))}
              </div>
              <div className="text-[12px] font-medium tracking-[0.1em] text-red uppercase">
                INCORRECT
              </div>
            </div>
          </div>
          <div className="relative w-full h-full bg-black">
            <div className="absolute inset-3 animate-pulse-red overflow-hidden gap-2">
              <div className="absolute inset-0 grid grid-cols-4 overflow-hidden">
                {[...Array(8)].map((_, index) => (
                  <div 
                    key={index} 
                    className="h-full w-full border-[4px] border-black"
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* OFFER */}
      {screenState === 'offer' && campaignData && eventData && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex w-full min-h-[70px] bg-black border-b border-grey-2 px-8">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center justify-center text-white">
                {typedGlyphs.map((glyph, index) => (
                  <Glyph key={index} name={glyph.name} color="#1CD760" />
                ))}
              </div>
              <div className="text-[12px] font-medium tracking-[0.1em] text-green uppercase">
                REWARD DETAILS
              </div>
            </div>
          </div>
          <div className="relative w-full h-full bg-black">
            <img 
              src={preloadedImage || campaignData.thumbnail} 
              alt="offer" 
              className="absolute inset-0 w-full h-full object-cover z-0 p-4"
            />
            <div className="absolute flex w-full h-full inset-0 overflow-hidden p-4">
              <div className="flex w-3/4 h-full items-center justify-start p-4">
                <div className="text-[140px] font-medium text-white uppercase leading-none">{campaignData.offer}</div>
              </div>
              <div className="flex flex-col items-end bg-black/50 w-1/4 h-full p-4 gap-1">
                <div className="text-[16px] font-medium text-white uppercase tracking-[0.1em] text-right">{eventData.venue.split(',')[0]}</div>
                <div className="text-[11px] font-medium text-white uppercase tracking-[0.1em] text-right">{eventData.date}</div>
                <div className="text-[11px] font-medium text-white uppercase tracking-[0.1em] text-right">{eventData.venue.split(',')[1]}</div>
                <div className="h-full w-full"></div>
                <div className="text-[11px] font-medium text-white uppercase tracking-[0.1em] text-right">{campaignData.artist}</div>
              </div>
            </div>
            <div className="absolute inset-3 overflow-hidden gap-2">
              <div className="absolute inset-0 grid grid-cols-4 overflow-hidden">
                {[...Array(8)].map((_, index) => (
                  <div 
                    key={index} 
                    className="h-full w-full border-[4px] border-black"
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* CALL IN */}
      {screenState === 'call-in' && (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <div className="flex w-full min-h-[70px] bg-black border-b border-grey-2 px-8">
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center justify-center text-white">
                {typedGlyphs.map((glyph, index) => (
                  <Glyph key={index} name={glyph.name} color="#FFFFFF" />
                ))}
              </div>
              <div className="text-[12px] font-medium tracking-[0.1em] text-green uppercase">
                Press any key to call in
              </div>
            </div>
          </div>
          <div className="relative w-full h-full bg-black">
            <div onClick={handleCallClick} className="absolute inset-3 bg-green overflow-hidden gap-2">
              <div className="absolute h-full w-full flex flex-col gap-2 items-center justify-start">
                <div className="flex flex-col gap-2">
                  <div className="text-[140px] font-medium tracking-[0.05em] leading-none text-white uppercase animate-slide-left">
                    CALL IN
                  </div>
                  <div className="text-[140px] font-medium tracking-[0.05em] leading-none text-white uppercase animate-slide-right">
                    TO WIN
                  </div>
                </div>
              </div>
              <div className="absolute inset-0 grid grid-cols-4 overflow-hidden">
                {[...Array(8)].map((_, index) => (
                  <div 
                    key={index} 
                    className="h-full w-full border-[4px] border-black"
                  ></div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DisplayScreen; 