import React from 'react';
import PowerButton from './PowerButton';

const ControlPanel = ({
  isActive,
  isHolding,
  holdProgress,
  selectedChannel,
  screenState,
  offerCountdown,
  handlePowerSequence,
  handleCallClick,
  typedGlyphs,
  setTypedGlyphs,
  audio,
  channels,
  setSelectedChannel
}) => {
  return (
    <div className="flex flex-col items-center justify-between min-w-[90px] h-full border-l border-grey-2">
      {/* This is the power/call button */}
      <div className="flex flex-col min-h-[70px] w-full items-center justify-center border-b border-grey-2">
        <PowerButton
          isActive={isActive}
          isHolding={isHolding}
          holdProgress={holdProgress}
          selectedChannel={selectedChannel}
          screenState={screenState}
          offerCountdown={offerCountdown}
          onClick={() => {
            if (!isActive) {
              handlePowerSequence(true);
            } else if (screenState === 'call-in') {
              handleCallClick();
            } else if (typedGlyphs.length > 0) {
              // Handle backspace
              setTypedGlyphs(prev => {
                const newGlyphs = [...prev];
                newGlyphs.pop();
                
                // First stop current audio
                audio.pause();
                audio.currentTime = 0;
                
                if (newGlyphs.length > 0) {
                  const lastGlyph = newGlyphs[newGlyphs.length - 1];
                  const lastChannel = channels.find(c => c.name === lastGlyph.name);
                  if (lastChannel) {
                    audio.src = lastChannel.song;
                    audio.load();
                    audio.addEventListener('canplaythrough', () => {
                      audio.play().catch(console.error);
                    }, { once: true });
                    setSelectedChannel(lastChannel);
                  }
                } else {
                  setSelectedChannel(null);
                }
                
                return newGlyphs;
              });
            } else if (selectedChannel) {
              handleCallClick();
            } else {
              handlePowerSequence(false);
            }
          }}
        />
      </div>

      {/* This is the microphone (non-interactive) */}
      <div className="grid grid-cols-2 gap-[6px]">
        {[...Array(10)].map((_, index) => (
          <div 
            key={index} 
            className="h-[10px] w-[10px] border border-grey-2 rounded-full"
          ></div>
        ))}
      </div>

      {/* This is the volume (non-interactive) */}
      <div className="flex flex-col min-h-[154px] w-full items-center justify-end pb-4">
        <div className="flex h-full w-full p-2 border-t border-grey-2">
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6.0293H12" stroke="#464646"/>
            <path d="M6 12.0293L6 0.0292962" stroke="#464646"/>
          </svg>
        </div>
        <div className="flex h-full w-full p-2 border-y border-grey-2">
          <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6.0293H12" stroke="#464646"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel; 