import React from 'react';

const glyphPaths = {
  acousticness: "M16.1931 1.89258L2.30884 16.6446L16.689 31.8926",
  danceability: "M4.73196 1.95898H9.33848V11.3448M9.33848 20.7306L2.0832 31.959M9.33848 20.7306L16.3059 31.959M9.33848 20.7306V11.3448M9.33848 11.3448L18.3212 7.14133V14.4542M9.33848 11.3448L1.21948 15.03",
  energy: "M3.83284 33.0271L20.2132 13.702L11.5629 3.0271L2.9126 13.702L20.2132 33.0271",
  liveness: "M9.99221 32.3389H19.4013M9.99221 32.3389H2.21948M9.99221 32.3389V21.7025M9.99221 21.7025H2.21948V19.1116V18.0207M9.99221 21.7025V16.1116M9.99221 16.1116H17.2195V19.1116M9.99221 16.1116V9.22523V7.52069M9.99221 7.52069V2.33887H15.0377M9.99221 7.52069H18.0377M9.99221 7.52069H3.17402",
  loudness: "M1.73999 0.743652V11.9694M1.73999 30.7437V19.0906M1.73999 11.9694C1.73999 11.9694 6.75339 10.1628 8.39654 7.49658C9.80533 5.2106 9.6913 0.743652 9.6913 0.743652M1.73999 11.9694V19.0906M1.73999 19.0906C1.73999 19.0906 7.43169 17.5019 10.2798 15.5595C13.3085 13.4939 14.4436 11.3166 15.6355 7.8497C16.7058 4.73625 16.3417 0.743652 16.3417 0.743652",
  speechiness: "M15.7591 3.9266L12.934 8.23152L1.49902 2.98486V32.9849L12.934 28.5454L16.4318 32.9849",
  tempo: "M2.02002 33.2285V21.7789M2.02002 21.7789V3.22852L15.9106 14.412L5.49318 19.9369M2.02002 21.7789L5.49318 19.9369M5.49318 19.9369L14.091 32.9179",
  valence: "M15.2133 15.1864L1.49902 3.47217V15.1864M1.49902 33.4722V15.1864M1.49902 15.1864L8.26093 21.9484"
};

const Glyph = ({ name, color = "white" }) => {
  if (!glyphPaths[name]) return null;

  return (
    <svg width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-1">
      <path d={glyphPaths[name]} stroke={color} strokeWidth="3"/>
    </svg>
  );
};

export default Glyph;