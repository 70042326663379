import React, { useState } from 'react';
import { collection, addDoc, GeoPoint, Timestamp, doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../services/firebase';

const CreateCampaign = () => {
  const [formData, setFormData] = useState({
    additional_info: '',
    artist: '',
    genres: [''],
    link: '',
    offer: '',
    promo_codes: [''],
    thumbnail: '',
    thumbnail_file: null,
    type: 'events',
    intro_audio: '',
    intro_audio_file: null,
    agent_id: '',
    event: {
      date: '',
      location: {
        latitude: '',
        longitude: ''
      },
      venue: '',
      city: ''
    },
    assets: {
      game_data: [{
        question: '',
        answer: ''
      }],
      game_types: ['']
    }
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const [uploadProgress, setUploadProgress] = useState({
    thumbnail: 0,
    intro_audio: 0
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleArrayInputChange = (e, index, arrayName) => {
    const { value } = e.target;
    setFormData(prev => {
      // Handle nested paths (e.g., 'assets.game_types')
      const path = arrayName.split('.');
      if (path.length === 1) {
        return {
          ...prev,
          [arrayName]: prev[arrayName].map((item, i) => i === index ? value : item)
        };
      } else {
        // Handle nested path
        return {
          ...prev,
          [path[0]]: {
            ...prev[path[0]],
            [path[1]]: prev[path[0]][path[1]].map((item, i) => i === index ? value : item)
          }
        };
      }
    });
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      event: {
        ...prev.event,
        location: {
          ...prev.event.location,
          [name]: value
        }
      }
    }));
  };

  const handleGameDataChange = (index, field, value) => {
    setFormData(prev => ({
      ...prev,
      assets: {
        ...prev.assets,
        game_data: prev.assets.game_data.map((item, i) => 
          i === index ? { ...item, [field]: value } : item
        )
      }
    }));
  };

  const addArrayItem = (arrayName) => {
    setFormData(prev => {
      // Handle nested paths (e.g., 'assets.game_types')
      const path = arrayName.split('.');
      if (path.length === 1) {
        return {
          ...prev,
          [arrayName]: [...prev[arrayName], '']
        };
      } else {
        // Handle nested path
        return {
          ...prev,
          [path[0]]: {
            ...prev[path[0]],
            [path[1]]: [...prev[path[0]][path[1]], '']
          }
        };
      }
    });
  };

  const removeArrayItem = (arrayName, index) => {
    setFormData(prev => {
      // Handle nested paths (e.g., 'assets.game_types')
      const path = arrayName.split('.');
      if (path.length === 1) {
        return {
          ...prev,
          [arrayName]: prev[arrayName].filter((_, i) => i !== index)
        };
      } else {
        // Handle nested path
        return {
          ...prev,
          [path[0]]: {
            ...prev[path[0]],
            [path[1]]: prev[path[0]][path[1]].filter((_, i) => i !== index)
          }
        };
      }
    });
  };

  const addGameDataItem = () => {
    setFormData(prev => ({
      ...prev,
      assets: {
        ...prev.assets,
        game_data: [...prev.assets.game_data, { question: '', answer: '' }]
      }
    }));
  };

  const removeGameDataItem = (index) => {
    if (formData.assets.game_data.length > 1) {
      setFormData(prev => ({
        ...prev,
        assets: {
          ...prev.assets,
          game_data: prev.assets.game_data.filter((_, i) => i !== index)
        }
      }));
    }
  };

  const handleFileChange = (e, type) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        [`${type}_file`]: file
      }));
    }
  };

  const uploadFile = async (file, type) => {
    if (!file) return null;

    const fileExtension = file.name.split('.').pop();
    const fileName = `${type}_${Date.now()}.${fileExtension}`;
    const storageRef = ref(storage, `campaigns/${fileName}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error(`Error uploading ${type}:`, error);
      throw new Error(`Failed to upload ${type}`);
    }
  };

  const validateForm = () => {
    if (!formData.artist.trim()) return 'Artist is required';
    if (!formData.offer.trim()) return 'Offer is required';
    if (!formData.agent_id.trim()) return 'Agent ID is required';
    if (!formData.event.venue.trim()) return 'Venue is required';
    if (!formData.event.city.trim()) return 'City is required';
    if (!formData.event.date) return 'Date is required';
    
    const lat = parseFloat(formData.event.location.latitude);
    const lng = parseFloat(formData.event.location.longitude);
    if (isNaN(lat) || lat < -90 || lat > 90) return 'Invalid latitude';
    if (isNaN(lng) || lng < -180 || lng > 180) return 'Invalid longitude';
    
    if (formData.genres.some(g => !g.trim())) return 'All genres must be filled out';
    if (formData.promo_codes.some(p => !p.trim())) return 'All promo codes must be filled out';
    if (formData.assets.game_types.some(t => !t.trim())) return 'All game types must be filled out';
    
    const hasEmptyGameData = formData.assets.game_data.some(
      ({ question, answer }) => !question.trim() || !answer.trim()
    );
    if (hasEmptyGameData) return 'All questions and answers must be filled out';
    
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submission started');
    
    const validationError = validateForm();
    if (validationError) {
      console.log('Validation failed:', validationError);
      setError(validationError);
      return;
    }
    console.log('Validation passed');

    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

    try {
      console.log('Starting file uploads');
      // Upload files first
      let thumbnailURL = formData.thumbnail;
      let introAudioURL = formData.intro_audio;

      if (formData.thumbnail_file) {
        console.log('Uploading thumbnail');
        thumbnailURL = await uploadFile(formData.thumbnail_file, 'thumbnail');
      }

      if (formData.intro_audio_file) {
        console.log('Uploading intro audio');
        introAudioURL = await uploadFile(formData.intro_audio_file, 'intro_audio');
      }

      console.log('Preparing campaign data');
      // Create the main campaign document
      const campaignData = {
        additional_info: formData.additional_info,
        artist: formData.artist,
        genres: formData.genres.filter(g => g.trim()),
        link: formData.link,
        offer: formData.offer,
        promo_codes: formData.promo_codes.filter(p => p.trim()),
        thumbnail: thumbnailURL,
        type: formData.type,
        intro_audio: introAudioURL,
        agent_id: formData.agent_id
      };

      console.log('Creating campaign document:', campaignData);
      const campaignRef = await addDoc(collection(db, 'campaigns'), campaignData);
      console.log('Campaign document created with ID:', campaignRef.id);
      
      // Create the event subdocument
      const eventData = {
        date: Timestamp.fromDate(new Date(formData.event.date)),
        location: new GeoPoint(
          parseFloat(formData.event.location.latitude),
          parseFloat(formData.event.location.longitude)
        ),
        venue: formData.event.venue,
        city: formData.event.city
      };
      
      console.log('Creating event document:', eventData);
      await setDoc(doc(db, 'campaigns', campaignRef.id, 'events', 'event1'), eventData);
      console.log('Event document created');

      // Create the assets subdocument
      const assetsData = {
        game_data: formData.assets.game_data,
        game_types: formData.assets.game_types.filter(t => t.trim())
      };
      
      console.log('Creating assets document:', assetsData);
      await setDoc(doc(db, 'campaigns', campaignRef.id, 'assets', 'asset1'), assetsData);
      console.log('Assets document created');
      
      console.log('Campaign creation completed successfully');
      setSuccess(true);
      // Reset form
      setFormData({
        additional_info: '',
        artist: '',
        genres: [''],
        link: '',
        offer: '',
        promo_codes: [''],
        thumbnail: '',
        thumbnail_file: null,
        type: 'events',
        intro_audio: '',
        intro_audio_file: null,
        agent_id: '',
        event: {
          date: '',
          location: {
            latitude: '',
            longitude: ''
          },
          venue: '',
          city: ''
        },
        assets: {
          game_data: [{
            question: '',
            answer: ''
          }],
          game_types: ['']
        }
      });
      
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      console.error('Error creating campaign:', err);
      setError('Failed to create campaign. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h2 className="text-2xl font-bold text-white mb-6">Create New Campaign</h2>
      
      {error && (
        <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded mb-4">
          {error}
        </div>
      )}
      
      {success && (
        <div className="bg-green-500/10 border border-green-500 text-green-500 px-4 py-2 rounded mb-4">
          Campaign created successfully!
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Main Campaign Info */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white">Campaign Information</h3>
          
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-grey-3 mb-1">Artist *</label>
              <input
                type="text"
                name="artist"
                value={formData.artist}
                onChange={handleInputChange}
                className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-grey-3 mb-1">Offer *</label>
              <input
                type="text"
                name="offer"
                value={formData.offer}
                onChange={handleInputChange}
                className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Additional Info</label>
            <textarea
              name="additional_info"
              value={formData.additional_info}
              onChange={handleInputChange}
              className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
              rows="3"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Link</label>
            <input
              type="url"
              name="link"
              value={formData.link}
              onChange={handleInputChange}
              className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Thumbnail</label>
            <div className="flex gap-4 items-start">
              <div className="flex-1">
                <input
                  type="url"
                  name="thumbnail"
                  value={formData.thumbnail}
                  onChange={handleInputChange}
                  placeholder="Enter URL or upload file"
                  className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                />
              </div>
              <div className="flex-shrink-0">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, 'thumbnail')}
                  className="hidden"
                  id="thumbnail-upload"
                />
                <label
                  htmlFor="thumbnail-upload"
                  className="cursor-pointer bg-grey-1 text-white px-4 py-2 rounded hover:bg-grey-2 transition-colors inline-block"
                >
                  Upload
                </label>
              </div>
              {formData.thumbnail_file && (
                <div className="text-sm text-grey-3">
                  Selected: {formData.thumbnail_file.name}
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Intro Audio</label>
            <div className="flex gap-4 items-start">
              <div className="flex-1">
                <input
                  type="url"
                  name="intro_audio"
                  value={formData.intro_audio}
                  onChange={handleInputChange}
                  placeholder="Enter URL or upload file"
                  className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                />
              </div>
              <div className="flex-shrink-0">
                <input
                  type="file"
                  accept="audio/*"
                  onChange={(e) => handleFileChange(e, 'intro_audio')}
                  className="hidden"
                  id="intro-audio-upload"
                />
                <label
                  htmlFor="intro-audio-upload"
                  className="cursor-pointer bg-grey-1 text-white px-4 py-2 rounded hover:bg-grey-2 transition-colors inline-block"
                >
                  Upload
                </label>
              </div>
              {formData.intro_audio_file && (
                <div className="text-sm text-grey-3">
                  Selected: {formData.intro_audio_file.name}
                </div>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Agent ID *</label>
            <input
              type="text"
              name="agent_id"
              value={formData.agent_id}
              onChange={handleInputChange}
              className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
              required
            />
          </div>

          {/* Genres Array */}
          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Genres</label>
            {formData.genres.map((genre, index) => (
              <div key={index} className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={genre}
                  onChange={(e) => handleArrayInputChange(e, index, 'genres')}
                  className="flex-1 bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem('genres', index)}
                  disabled={formData.genres.length === 1}
                  className="px-3 py-2 bg-red-500/10 text-red-500 rounded hover:bg-red-500/20 disabled:opacity-50"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem('genres')}
              className="text-sm text-grey-3 hover:text-white"
            >
              + Add Genre
            </button>
          </div>

          {/* Promo Codes Array */}
          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Promo Codes</label>
            {formData.promo_codes.map((code, index) => (
              <div key={index} className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={code}
                  onChange={(e) => handleArrayInputChange(e, index, 'promo_codes')}
                  className="flex-1 bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem('promo_codes', index)}
                  disabled={formData.promo_codes.length === 1}
                  className="px-3 py-2 bg-red-500/10 text-red-500 rounded hover:bg-red-500/20 disabled:opacity-50"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem('promo_codes')}
              className="text-sm text-grey-3 hover:text-white"
            >
              + Add Promo Code
            </button>
          </div>
        </div>

        {/* Event Information */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white">Event Information</h3>
          
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-grey-3 mb-1">Venue *</label>
              <input
                type="text"
                name="venue"
                value={formData.event.venue}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  event: { ...prev.event, venue: e.target.value }
                }))}
                className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-grey-3 mb-1">City *</label>
              <input
                type="text"
                name="city"
                value={formData.event.city}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  event: { ...prev.event, city: e.target.value }
                }))}
                className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Date *</label>
            <input
              type="datetime-local"
              name="date"
              value={formData.event.date}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                event: { ...prev.event, date: e.target.value }
              }))}
              className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
              required
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-grey-3 mb-1">Latitude *</label>
              <input
                type="number"
                step="any"
                name="latitude"
                value={formData.event.location.latitude}
                onChange={handleLocationChange}
                className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                required
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-grey-3 mb-1">Longitude *</label>
              <input
                type="number"
                step="any"
                name="longitude"
                value={formData.event.location.longitude}
                onChange={handleLocationChange}
                className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                required
              />
            </div>
          </div>
        </div>

        {/* Game Assets */}
        <div className="space-y-4">
          <h3 className="text-lg font-semibold text-white">Game Assets</h3>

          {/* Game Types Array */}
          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Game Types</label>
            {formData.assets.game_types.map((type, index) => (
              <div key={index} className="flex gap-2 mb-2">
                <input
                  type="text"
                  value={type}
                  onChange={(e) => handleArrayInputChange(e, index, 'assets.game_types')}
                  className="flex-1 bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                />
                <button
                  type="button"
                  onClick={() => removeArrayItem('assets.game_types', index)}
                  disabled={formData.assets.game_types.length === 1}
                  className="px-3 py-2 bg-red-500/10 text-red-500 rounded hover:bg-red-500/20 disabled:opacity-50"
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={() => addArrayItem('assets.game_types')}
              className="text-sm text-grey-3 hover:text-white"
            >
              + Add Game Type
            </button>
          </div>

          {/* Game Data */}
          <div>
            <label className="block text-sm font-medium text-grey-3 mb-1">Game Questions & Answers</label>
            {formData.assets.game_data.map((item, index) => (
              <div key={index} className="border border-grey-2 rounded p-4 mb-4">
                <div className="flex justify-between mb-2">
                  <span className="text-white">Question {index + 1}</span>
                  <button
                    type="button"
                    onClick={() => removeGameDataItem(index)}
                    disabled={formData.assets.game_data.length === 1}
                    className="text-red-500 hover:text-red-400 disabled:opacity-50"
                  >
                    Remove
                  </button>
                </div>
                <div className="space-y-2">
                  <input
                    type="text"
                    value={item.question}
                    onChange={(e) => handleGameDataChange(index, 'question', e.target.value)}
                    placeholder="Question"
                    className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                  />
                  <input
                    type="text"
                    value={item.answer}
                    onChange={(e) => handleGameDataChange(index, 'answer', e.target.value)}
                    placeholder="Answer"
                    className="w-full bg-grey-1 text-white border border-grey-2 rounded px-3 py-2"
                  />
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={addGameDataItem}
              className="text-sm text-grey-3 hover:text-white"
            >
              + Add Question & Answer
            </button>
          </div>
        </div>

        <div className="flex justify-end pt-6">
          <button
            type="submit"
            disabled={isSubmitting}
            className="bg-grey-1 text-white px-6 py-2 rounded hover:bg-grey-2 transition-colors disabled:opacity-50"
          >
            {isSubmitting ? 'Creating...' : 'Create Campaign'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCampaign; 